import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { ConfigService } from './config.service';
import { LoginService } from './login.service';
import { LogService } from './log.service';

import { Content, ReportStatus } from '../structures/content';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class LibraryService {
    emitWarningDialog: Subject<any> = new Subject();
    emitContentDetailsDialog: Subject<number> = new Subject();

    refreshLibrary: Subject<any> = new Subject();

    contentDisabled: Subject<Content> = new Subject();
    contentEnabled: Subject<Content> = new Subject();
    contentArchived: Subject<Content> = new Subject();
    contentUnarchived: Subject<Content> = new Subject();
    contentDeleted: Subject<Content> = new Subject();

    addedDraft: Subject<Content> = new Subject();
    updatedDraft: Subject<Content> = new Subject();
    deletedDraft: Subject<Content> = new Subject();

    deletedChild: Subject<{ child: Content; parent: Content }> = new Subject();

    viewInLibrary: Subject<number> = new Subject();

    foldAll: Subject<void> = new Subject();

    detectChanges: Subject<void> = new Subject();
    setNationalCatalog: Subject<void> = new Subject();

    constructor(
        private router: Router,
        private http: HttpClient,
        private configService: ConfigService,
        private loginService: LoginService,
        private logService: LogService
    ) {}

    /**
     * récupère un contenu complet à partir de son ID
     * @param {number} contentId l'id du contenu à récupérer
     */
    @log() getContent(contentId: number, logged?: boolean) {
        const params: any = {};
        if (logged) {
            params.log = 'teacher';
        }

        return this.http.get(`/contents/${contentId}`, { params });
    }

    @log() getContentByUcode(body: any) {
        return this.http.post(`/contents/get_by_ucodes`, body);
    }

    /**
     * Récupère les contenus en fonction des filtres sélectionnés
     * @param {object} filters les filtres sélectionnés (dropdowns)
     */
    @log() getContents(filters: any) {
        const params: any = {};
        if (filters.domain.length) {
            params.domain = filters.domain.join('|');
        }
        if (filters.level.length) {
            params.level = filters.level.join('|');
        }
        if (filters.category.length) {
            params.category = filters.category.join('|');
        }
        if (filters.type.length) {
            params.type = filters.type.join('|');
        }
        if (filters.localStructure) {
            params.localStructure = filters.localStructure;
        } else {
            if (
                this.loginService.getUser().roles.siteTeacher ||
                this.loginService.getUser().roles.corporationTeacher
            ) {
                params.localStructure = this.loginService.getUser().localStructure;
            } else if (
                !this.loginService.getUser().roles.nationalAdmin &&
                !this.loginService.getUser().roles.nationalTeacher
            ) {
                params.localStructure = '|' + this.loginService.getUser().localStructure;
            }
        }

        if (filters.author) {
            params.author = filters.author;
        }
        if (filters.search) {
            params.search = filters.search;
        }
        if (filters.timecreatedstart) {
            params.timecreatedstart = filters.timecreatedstart;
        }
        if (filters.timecreatedend) {
            params.timecreatedend = filters.timecreatedend;
        }
        if (filters.timemodifiedstart) {
            params.timemodifiedstart = filters.timemodifiedstart;
        }
        if (filters.timemodifiedend) {
            params.timemodifiedend = filters.timemodifiedend;
        }
        if (filters.note) {
            params.note_avg_min = Math.min(...filters.note.map((n) => n.note_avg_min));
            params.note_avg_max = Math.max(...filters.note.map((n) => n.note_avg_max));
        }
        params.offset = filters.offset ? filters.offset : 0;
        params.limit = filters.limit ? filters.limit : 30;

        params.showdisabled = false;

        return this.http.get(`/contents`, { params });
    }

    @log() getCatalogContents(filters: any) {
        const params: any = {};
        if (filters.domain.length) {
            params.domain = filters.domain.join('|');
        }
        if (filters.category.length) {
            params.category = filters.category.join('|');
        }
        if (filters.level.length) {
            params.level = filters.level.join('|');
        }
        if (filters.localStructure) {
            params.localStructure = filters.localStructure;
        }

        if (filters.search) {
            params.search = filters.search;
        }

        params.offset = filters.offset ? filters.offset : 0;
        params.limit = filters.limit ? filters.limit : 30;

        return this.http.get(`/contents/catalog`, { params });
    }

    @log() getReportedContents(filters: any) {
        const params: any = {};
        if (filters.structureid) {
            params.structureid = filters.structureid;
        }
        if (filters.timereportedstart) {
            params.timereportedstart = filters.timereportedstart;
        }
        if (filters.timereportedend) {
            params.timereportedend = filters.timereportedend;
        }

        if (filters.author) {
            params.author = filters.author;
        }
        if (filters.search) {
            params.search = filters.search;
        }
        if (filters.status) {
            params.status = filters.status;
        }
        if (filters.mine) {
            params.mine = filters.mine;
        }

        params.offset = filters.offset ? filters.offset : 0;
        params.limit = filters.limit ? filters.limit : 30;

        return this.http.get(`/contents/reports`, { params });
    }

    @log() getDisabledContents(filters: any) {
        const params: any = {};
        if (filters.domain.length) {
            params.domain = filters.domain.join('|');
        }
        if (filters.level.length) {
            params.level = filters.level.join('|');
        }
        if (filters.category.length) {
            params.category = filters.category.join('|');
        }
        if (filters.type.length) {
            params.type = filters.type.join('|');
        }
        if (filters.localStructure) {
            params.localStructure = filters.localStructure;
        } else {
            if (
                this.loginService.getUser().roles.siteTeacher ||
                this.loginService.getUser().roles.corporationTeacher
            ) {
                params.localStructure = this.loginService.getUser().localStructure;
            } else if (!this.loginService.getUser().roles.nationalAdmin) {
                params.localStructure = '|' + this.loginService.getUser().localStructure;
            }
        }

        if (filters.author) {
            params.author = filters.author;
        }
        if (filters.search) {
            params.search = filters.search;
        }
        if (filters.timecreatedstart) {
            params.timecreatedstart = filters.timecreatedstart;
        }
        if (filters.timecreatedend) {
            params.timecreatedend = filters.timecreatedend;
        }
        if (filters.timemodifiedstart) {
            params.timemodifiedstart = filters.timemodifiedstart;
        }
        if (filters.timemodifiedend) {
            params.timemodifiedend = filters.timemodifiedend;
        }
        if (filters.note) {
            params.note_avg_min = Math.min(...filters.note.map((n) => n.note_avg_min));
            params.note_avg_max = Math.max(...filters.note.map((n) => n.note_avg_max));
        }
        params.offset = filters.offset ? filters.offset : 0;
        params.limit = filters.limit ? filters.limit : 30;
        params.showdisabled = true;

        return this.http.get(`/contents`, { params });
    }

    @log() getPreviousVersionContents(filters: any) {
        const params: any = {};
        if (filters.domain.length) {
            params.domain = filters.domain.join('|');
        }
        if (filters.level.length) {
            params.level = filters.level.join('|');
        }
        if (filters.category.length) {
            params.category = filters.category.join('|');
        }
        if (filters.type.length) {
            params.type = filters.type.join('|');
        }
        if (filters.localStructure) {
            params.localStructure = filters.localStructure;
        } else {
            if (
                this.loginService.getUser().roles.siteTeacher ||
                this.loginService.getUser().roles.corporationTeacher
            ) {
                params.localStructure = this.loginService.getUser().localStructure;
            } else if (!this.loginService.getUser().roles.nationalAdmin) {
                params.localStructure = '|' + this.loginService.getUser().localStructure;
            }
        }

        if (filters.author) {
            params.author = filters.author;
        }
        if (filters.search) {
            params.search = filters.search;
        }
        if (filters.timecreatedstart) {
            params.timecreatedstart = filters.timecreatedstart;
        }
        if (filters.timecreatedend) {
            params.timecreatedend = filters.timecreatedend;
        }
        if (filters.timemodifiedstart) {
            params.timemodifiedstart = filters.timemodifiedstart;
        }
        if (filters.timemodifiedend) {
            params.timemodifiedend = filters.timemodifiedend;
        }
        if (filters.note) {
            params.note_avg_min = Math.min(...filters.note.map((n) => n.note_avg_min));
            params.note_avg_max = Math.max(...filters.note.map((n) => n.note_avg_max));
        }
        params.offset = filters.offset ? filters.offset : 0;
        params.limit = filters.limit ? filters.limit : 30;

        return this.http.get(`/contents/hidden`, { params });
    }

    @log() getReportedContentsCSV(): Observable<HttpResponse<any>> {
        const params: any = {
            export: true
        };

        return this.http.get(`/contents/reports`, {
            params,
            observe: 'response',
            responseType: 'blob'
        });
    }

    @log() getH5PTypes(): Observable<any> {
        return this.http.get(`/h5p-types`);
    }

    @log() getAuthors(): Observable<any> {
        return this.http.get(`/contents/author`);
    }

    @log() markUserAsAuthor(contentId: number, userId: number): Observable<any> {
        return this.http.put(`/contents/${contentId}/user/${userId}`, '');
    }

    @log() getArchivedAuthors(): Observable<any> {
        return this.http.get(`/contents/author?archive=true`);
    }

    @log() markReportedContentAsSeen(id: number): Observable<any> {
        return this.http.post(`/contents/report/${id}/seen`, '');
    }

    @log() getReportedContent(reportId: number) {
        return this.http.get(`/contents/report/${reportId}`);
    }
    @log() closeActivityReport(reportId: number, answer: string) {
        return this.http.put(`/contents/report/${reportId}`, { status: 'closed', answer });
    }

    @log() cancelActivityReport(reportId: number, answer: string) {
        return this.http.put(`/contents/report/${reportId}`, { status: 'canceled', answer });
    }

    @log() updateActivityReportStatus(reportId: number, status: ReportStatus, answer?: string) {
        return this.http.put(`/contents/report/${reportId}`, { status, answer });
    }

    @log() getMinorUpdateChange(contentId: number) {
        return this.http.get(`/contents/${contentId}/approval/get_differences`);
    }

    @log() getDrafts() {
        return this.http.get(`/contents/draft`);
    }

    @log() getSentToValidation() {
        return this.http.get(`/contents/sentToValidation`);
    }

    @log() getWaitingForApproval() {
        return this.http.get(`/contents/waitingForApproval`);
    }

    @log() approveContent(content: Content, comment: string) {
        const params = {
            publicationMode: content.publicationMode,
            comment
        };

        return this.http.put(`/contents/${content.id}/approval/approve`, params);
    }

    @log() rejectContent(content: Content, comment: string) {
        return this.http.put(`/contents/${content.id}/approval/reject`, { comment });
    }

    @log() sendContentToValidation(body: any) {
        return this.http.put(`/contents/${body.id}/approval/send_to_validation`, body);
    }

    @log() sendReminder(content: Content) {
        return this.http.put(`/contents/${content.id}/approval/remind`, content);
    }

    @log() getContentInfo(content: Content) {
        return this.http.get(`/contents/${content.id}/approval/info`);
    }

    @log() getContentHistory(content: Content) {
        return this.http.get(`/contents/${content.id}/approval/history`);
    }

    @log() delegateValidation(params: any) {
        return this.http.put(`/contents/${params.id}/approval/delegate`, params);
    }

    @log() updateValidators(content: Content, validators: Array<number>) {
        return this.http.put(`/contents/${content.id}/approval/update_validators`, { validators });
    }

    @log() cancelValidation(params: any) {
        return this.http.put(`/contents/${params.id}/approval/cancel`, params);
    }

    @log() addValidator(params: any) {
        return this.http.put(`/contents/${params.id}/approval/add`, params);
    }

    /**
     * Récupère les comptes de contenus pour une recherche getContents
     * @param {object} filters les filtres sélectionnés (dropdowns)
     */
    @log() getContentsCount(filters: any) {
        const params: any = {};
        if (filters.domain.length) {
            params.domain = filters.domain.join('|');
        }
        if (filters.level.length) {
            params.level = filters.level.join('|');
        }
        if (filters.category.length) {
            params.category = filters.category.join('|');
        }
        if (filters.type.length) {
            params.type = filters.type.join('|');
        }
        if (filters.localStructure) {
            params.localStructure = filters.localStructure;
        } else {
            if (
                this.loginService.getUser().roles.siteTeacher ||
                this.loginService.getUser().roles.corporationTeacher
            ) {
                params.localStructure = this.loginService.getUser().localStructure;
            } else if (
                !this.loginService.getUser().roles.nationalAdmin &&
                !this.loginService.getUser().roles.nationalTeacher
            ) {
                params.localStructure = '|' + this.loginService.getUser().localStructure;
            }
        }
        if (filters.author) {
            params.author = filters.author;
        }
        if (filters.search) {
            params.search = filters.search;
        }
        if (filters.timecreatedstart) {
            params.timecreatedstart = filters.timecreatedstart;
        }
        if (filters.timecreatedend) {
            params.timecreatedend = filters.timecreatedend;
        }
        if (filters.timemodifiedstart) {
            params.timemodifiedstart = filters.timemodifiedstart;
        }
        if (filters.timemodifiedend) {
            params.timemodifiedend = filters.timemodifiedend;
        }
        params.offset = filters.offset ? filters.offset : 0;
        params.limit = filters.limit ? filters.limit : 30;
        params.showdisabled = false;

        return this.http.get(`/contents?count`, { params });
    }

    @log() getCatalogContentsCount(filters: any) {
        const params: any = {};
        if (filters.domain.length) {
            params.domain = filters.domain.join('|');
        }
        if (filters.category.length) {
            params.category = filters.category.join('|');
        }
        if (filters.localStructure) {
            params.localStructure = filters.localStructure;
        }

        if (filters.search) {
            params.search = filters.search;
        }

        params.offset = filters.offset ? filters.offset : 0;
        params.limit = filters.limit ? filters.limit : 30;

        return this.http.get(`/contents/catalog?count`, { params });
    }

    @log() getReportedContentsCount(filters: any) {
        const params: any = {};
        if (filters.structureid) {
            params.structureid = filters.structureid;
        }
        if (filters.timereportedstart) {
            params.timereportedstart = filters.timereportedstart;
        }
        if (filters.timereportedend) {
            params.timereportedend = filters.timereportedend;
        }

        if (filters.author) {
            params.author = filters.author;
        }
        if (filters.search) {
            params.search = filters.search;
        }
        if (filters.status) {
            params.status = filters.status;
        }
        if (filters.mine) {
            params.mine = filters.mine;
        }

        return this.http.get(`/contents/reports?count`, { params });
    }

    @log() getDisabledContentsCount(filters: any) {
        const params: any = {};
        if (filters.domain.length) {
            params.domain = filters.domain.join('|');
        }
        if (filters.level.length) {
            params.level = filters.level.join('|');
        }
        if (filters.category.length) {
            params.category = filters.category.join('|');
        }
        if (filters.type.length) {
            params.type = filters.type.join('|');
        }
        if (filters.localStructure) {
            params.localStructure = filters.localStructure;
        } else {
            if (
                this.loginService.getUser().roles.siteTeacher ||
                this.loginService.getUser().roles.corporationTeacher
            ) {
                params.localStructure = this.loginService.getUser().localStructure;
            } else if (!this.loginService.getUser().roles.nationalAdmin) {
                params.localStructure = '|' + this.loginService.getUser().localStructure;
            }
        }
        if (filters.author) {
            params.author = filters.author;
        }
        if (filters.search) {
            params.search = filters.search;
        }
        if (filters.timecreatedstart) {
            params.timecreatedstart = filters.timecreatedstart;
        }
        if (filters.timecreatedend) {
            params.timecreatedend = filters.timecreatedend;
        }
        if (filters.timemodifiedstart) {
            params.timemodifiedstart = filters.timemodifiedstart;
        }
        if (filters.timemodifiedend) {
            params.timemodifiedend = filters.timemodifiedend;
        }
        params.offset = filters.offset ? filters.offset : 0;
        params.limit = filters.limit ? filters.limit : 30;
        params.showdisabled = true;

        return this.http.get(`/contents?count`, { params });
    }

    @log() getPreviousVersionContentsCount(filters: any) {
        const params: any = {};
        if (filters.domain.length) {
            params.domain = filters.domain.join('|');
        }
        if (filters.level.length) {
            params.level = filters.level.join('|');
        }
        if (filters.category.length) {
            params.category = filters.category.join('|');
        }
        if (filters.type.length) {
            params.type = filters.type.join('|');
        }
        if (filters.localStructure) {
            params.localStructure = filters.localStructure;
        } else {
            if (
                this.loginService.getUser().roles.siteTeacher ||
                this.loginService.getUser().roles.corporationTeacher
            ) {
                params.localStructure = this.loginService.getUser().localStructure;
            } else if (!this.loginService.getUser().roles.nationalAdmin) {
                params.localStructure = '|' + this.loginService.getUser().localStructure;
            }
        }
        if (filters.author) {
            params.author = filters.author;
        }
        if (filters.search) {
            params.search = filters.search;
        }
        if (filters.timecreatedstart) {
            params.timecreatedstart = filters.timecreatedstart;
        }
        if (filters.timecreatedend) {
            params.timecreatedend = filters.timecreatedend;
        }
        if (filters.timemodifiedstart) {
            params.timemodifiedstart = filters.timemodifiedstart;
        }
        if (filters.timemodifiedend) {
            params.timemodifiedend = filters.timemodifiedend;
        }
        params.offset = filters.offset ? filters.offset : 0;
        params.limit = filters.limit ? filters.limit : 30;

        return this.http.get(`/contents/hidden?count`, { params });
    }

    openActivity(activity) {
        return this.getContent(activity.id, true).subscribe(
            (data: any) => {
                let popup = null;
                const warningTitle = "Impossible d'ouvrir la page";
                const warningBody =
                    "Vous utilisez un bloqueur de pop-ups qui vous empêche d'ouvrir un nouvel onglet. <br >Veuillez modifier les paramètres de votre navigateur pour autoriser les pop-ups pour le site : <br><strong>easi-training.fr</strong>";
                if (data.hasprice > 0 && !this.loginService.getUser().roles.nationalAdmin) {
                    this.openWarning(
                        "Les contenus payants ne peuvent êtres consultés que dans l'application Learning",
                        "Impossible d'éxécuter ce contenu"
                    );
                } else if (data.path !== '') {
                    switch (data.type) {
                        case 'scorm':
                            popup = window.open(data.path, activity.title);
                            if (!popup) {
                                this.openWarning(warningBody, warningTitle);
                            }
                            break;
                        case 'url':
                            popup = window.open(data.url, activity.title);
                            if (!popup) {
                                this.openWarning(warningBody, warningTitle);
                            }
                            break;
                        case 'quiz':
                            popup = window.open(
                                `${this.configService.getFrontEndPoint()}/quizPlayer/#/${
                                    activity.id
                                }?teacher=true`,
                                '_blank'
                            );
                            if (!popup) {
                                this.openWarning(warningBody, warningTitle);
                            }
                            break;
                        case 'n4s':
                            this.openWarning(
                                'Impossible de prévisualiser un contenu N4S.<br/> Il ne peut être lancer que depuis Learning.',
                                'Prévisualisation impossible'
                            );
                            break;
                        case 'video':
                            popup = window.open(
                                `${this.configService.getFrontEndPoint()}/#/videoPlayer/${data.id}`,
                                '_blank'
                            );
                            if (!popup) {
                                this.openWarning(warningBody, warningTitle);
                            }
                            break;
                        case 'h5pactivity':
                            popup = window.open(
                                `${this.configService.getFrontEndPoint()}/#/h5pPlayer/${data.id}`,
                                '_blank'
                            );
                            if (!popup) {
                                this.openWarning(warningBody, warningTitle);
                            }
                            break;
                        case 'presentiel':
                            this.openContentDetailsDialog(data.id);
                            break;
                        default:
                            popup = window.open(data.path, '_blank');
                            if (!popup) {
                                this.openWarning(warningBody, warningTitle);
                            }
                    }
                } else {
                    this.openWarning(
                        'Cette activité est nationale, vous ne pouvez pas la lancer',
                        warningTitle
                    );
                }
            },
            (error: HttpErrorResponse) => {
                this.logService.error('LibraryService: openActivity', error);
            }
        );
    }

    @log() duplicateContent(oldContent, isCopy: boolean): any {
        return this.getContent(oldContent.id).pipe(
            switchMap((content: Content) => {
                let serviceName: string;
                if (content.level === 'activity' || !content.level) {
                    serviceName = '/activities';
                } else {
                    serviceName = '/assemblies';
                }

                const newContent: Content = {};
                const commonPropsToCopy = [
                    'title',
                    'description',
                    'hasprice',
                    'domains',
                    'localStructure',
                    'author',
                    'provider',
                    'copyright',
                    'taxonomy',
                    'sites',
                    'keywords',
                    'ordered',
                    'duration',
                    'version'
                ];
                const activityPropsToCopy = ['category', 'type'];
                const assemblyPropsToCopy = ['level', 'children'];

                // copy the received data to the new content
                commonPropsToCopy.map((propName) => {
                    newContent[propName] = content[propName];
                });
                if (!newContent.copyright) {
                    newContent.copyright = 'no copyright';
                }
                newContent.enabled = true;
                newContent.hidden = false;
                newContent.status = 'draft';
                newContent.author = this.loginService.getUser().id;
                newContent.localStructure = this.loginService.getUser().localStructure
                    ? this.loginService.getUser().localStructure
                    : newContent.localStructure;
                newContent.basedOn = content.id;

                newContent.title = `${isCopy ? 'Copie de ' : ''}${content.title}`;
                newContent.desiredPublicationMode = isCopy ? 'new' : 'update';

                if (content.type === 'h5pactivity') {
                    newContent.fileid = content.fileid;
                    newContent.filename = content.filename;
                }

                if (serviceName === '/assemblies') {
                    // for assemblies
                    // Copy assemblies specific props

                    assemblyPropsToCopy.map(
                        (propName) => (newContent[propName] = content[propName])
                    );

                    if (newContent.children === undefined) {
                        newContent.children = [];
                    } else {
                        newContent.children = newContent.children.map((child) => ({
                            id: child.id,
                            position: child.position,
                            orderNumber: child.orderNumber
                        }));
                    }
                } else {
                    // for activities
                    // Copy activities specific props
                    activityPropsToCopy.map(
                        (propName) => (newContent[propName] = content[propName])
                    );

                    if (content.downloadUrl) {
                        newContent.downloadUrl = content.downloadUrl;
                    }
                    newContent.assignable = true;
                    if (newContent.type === 'quiz') {
                        newContent.localStructure = content.localStructure;
                    }
                }

                return this.http.post(serviceName, newContent);
            })
        );
    }

    /**
     * Enables or disables the selected content
     * @param id the id of the content
     * @param {boolean} enable true : enable / false : disable
     */
    enableContent(content: any) {
        const localContent = Object.assign({}, content);
        localContent.enabled = true;
        if (localContent.level === 'activity' || !localContent.level) {
            return this.updateActivity(localContent);
        } else {
            return this.updateAssembly(localContent, undefined);
        }
    }

    /**
     * Enables or disables the selected content
     * @param id the id of the content
     * @param {boolean} enable true : enable / false : disable
     */
    disableContent(content: any, comment?: string) {
        const localContent = Object.assign({}, content);
        localContent.enabled = false;
        localContent.comment = comment;
        if (localContent.level === 'activity' || !localContent.level) {
            return this.updateActivity(localContent);
        } else {
            return this.updateAssembly(localContent, undefined);
        }
    }

    @log() recursiveAction(content: any, action: string, comment?: string) {
        return this.http.post('/assemblies/recursive_action/' + content.id, { action, comment });
    }

    @log() createActivity(
        content: Content,
        file?: File,
        guide?: File,
        visuel?: File,
        h5pContent?: File
    ) {
        const body = new FormData();
        body.append('DATAJson', JSON.stringify(content));
        if (file) {
            body.append('file', file);
        }
        if (guide) {
            body.append('guide', guide);
        }
        if (visuel) {
            body.append('visuel', visuel);
        }
        if (h5pContent) {
            body.append('H5PContent', h5pContent);
        }

        return this.http.post('/activities', body);
    }

    @log() createAssembly(content: Content, guide?: File, visuel?: File) {
        const body = new FormData();
        body.append('DATAJson', JSON.stringify(content));
        if (guide) {
            body.append('guide', guide);
        }
        if (visuel) {
            body.append('visuel', visuel);
        }

        return this.http.post('/assemblies', body);
    }

    @log() updateActivity(content, file?: any, guide?: File, visuel?: File) {
        if (content.type === 'quiz') {
            if (file) {
                const blob = new Blob([file], {
                    type: 'application/json'
                });
                file = blob;
            }
        }

        const body = new FormData();
        body.append('DATAJson', JSON.stringify(content));

        if (file) {
            body.append('file', file);
        }

        if (guide) {
            body.append('guide', guide);
        }
        if (visuel) {
            body.append('visuel', visuel);
        }

        return this.http.post('/activities/' + content.id, body);
    }

    @log() updateAssembly(content: Content, guide?: File, visuel?: File) {
        const params = {};

        const body = new FormData();
        body.append('DATAJson', JSON.stringify(content));

        if (guide) {
            body.append('guide', guide);
        }
        if (visuel) {
            body.append('visuel', visuel);
        }

        return this.http.post('/assemblies/' + content.id, body, { params });
    }

    @log() renameMedia(content: Content) {
        const { title } = content;
        return this.http.put(`/contents/${content.id}/rename`, { title });
    }

    @log() deleteContent(content: Content) {
        return this.http.delete(`/contents/${content.id}`);
    }
    @log() duplicateDraft(draft: Content) {
        return this.http.post(`/contents/${draft.id}/duplicate_draft`, undefined);
    }
    @log() deleteDraft(draft: Content) {
        return this.http.delete(`/contents/${draft.id}/draft`);
    }
    @log() getParentOf(contentId: number, offset: number, limit: number) {
        const params = {
            limit,
            offset
        };
        return this.http.get(`/contents/${contentId}/parents`, { params });
    }

    @log() nationalizeContent(contentId: number, publicationMode: string) {
        return this.http.post(`/contents/nationalize/${contentId}/${publicationMode}`, {});
    }

    @log() reportActivityError(contentId: number, comment: string, file: File) {
        const body = new FormData();
        body.append('message', JSON.stringify(comment));
        if (file) {
            body.append('file', file);
        }

        return this.http.post(`/contents/${contentId}/report`, body);
    }

    /**
     * Attribue une note à une activité.
     */
    @log() rateContent(contentId: number, note: 1 | 2 | 3 | 4 | 5) {
        return this.http.post<boolean>(`/contents/${contentId}/note`, { note });
    }

    /**
     * Modifie note à une activité. Seulement possible si l'activité a été modifiée après ma note
     */
    @log() updateRateContent(contentId: number, note: 1 | 2 | 3 | 4 | 5) {
        return this.http.put<boolean>(`/contents/${contentId}/note`, { note });
    }

    @log() updateMassContent(file: File): Observable<any> {
        const body = new FormData();
        body.append('csv', file);

        return this.http.post<any>(`/contents/bulk_update`, body);
    }

    @log() getDomains() {
        return this.http.get(`/domains`);
    }
    @log() getCategories() {
        return this.http.get(`/categories`);
    }
    @log() getProviders() {
        return this.http.get(`/providers`);
    }
    @log() getTaxonomies() {
        return this.http.get(`/taxonomies`);
    }

    getIcon(content: Content): string {
        switch (content.category) {
            case 'positionnement':
                return 'icon-positionnement-cercle';
            case 'preparation':
                return 'icon-Preparation-cercle';
            case 'ressource':
                return 'icon-ressource-cercle';
            case 'travail':
                return 'icon-Miseenapplcation-cercle';
            case 'evaluation':
                return 'icon-evaluation-cercle';
            case 'corrige':
                return 'icon-Corrige-cercle';
            case 'tp':
                return 'icon-TP-cercle';
            case 'presentiel':
                return 'icon-activite-presentielle-cercle';
            case 'url':
                return 'icon-lien-cercle';
            case 'devoir':
                return 'icon-remise-devoir';
            default:
                switch (content.level) {
                    case 'parcours':
                        return 'icon-parcours-cercle';
                    case 'bloc':
                        return 'icon-bloc-cercle';
                    case 'competence':
                        return 'icon-competence-cercle';
                    case 'module':
                        return 'icon-module-cercle';
                    case 'sequence':
                        return 'icon-sequence-cercle';
                    default:
                        return 'icon-activite-cercle';
                }
        }
    }

    getDraftIcon(content: Content): string {
        switch (content.category) {
            case 'positionnement':
                return 'icon-positionnement';
            case 'preparation':
                return 'icon-Preparation';
            case 'ressource':
                return 'icon-ressource';
            case 'travail':
                return 'icon-Miseenapplcation';
            case 'evaluation':
                return 'icon-evaluation';
            case 'corrige':
                return 'icon-Corrige';
            case 'tp':
                return 'icon-TP';
            case 'presentiel':
                return 'icon-activite-presentielle';
            case 'url':
                return 'icon-lien';
            case 'devoir':
                return 'icon-devoir';
            default:
                switch (content.level) {
                    case 'parcours':
                        return 'icon-parcours';
                    case 'bloc':
                        return 'icon-bloc';
                    case 'competence':
                        return 'icon-competence';
                    case 'module':
                        return 'icon-module';
                    case 'sequence':
                        return 'icon-sequence';
                }
        }
    }

    getTypeLabel(content: Content): string {
        switch (content.type) {
            case 'quiz': {
                return 'test';
            }
            case 'h5pactivity': {
                return 'media';
            }
            default: {
                return content.type;
            }
        }
    }

    convertKeywordsToArray(keywordsString) {
        if (Array.isArray(keywordsString)) {
            return keywordsString;
        }
        if (!keywordsString || typeof keywordsString !== 'string') {
            return [];
        }

        let i;
        const keywordsArrayWithoutEmptyString = [];
        const keywordsArray = keywordsString.split(';');

        for (i = 0; i < keywordsArray.length; i++) {
            keywordsArray[i] = keywordsArray[i].trim();
            if (keywordsArray[i] !== '') {
                keywordsArrayWithoutEmptyString.push(keywordsArray[i]);
            }
        }

        return keywordsArrayWithoutEmptyString;
    }

    getCategoryAndLevelLabelFromKey(entry) {
        switch (entry) {
            case 'parcours':
                return 'Parcours';
            case 'bloc':
                return 'Bloc';
            case 'competence':
                return 'Compétence';
            case 'module':
                return 'Module';
            case 'sequence':
                return 'Séquence';
            case 'activity':
                return 'Activité';
            case 'corrige':
                return 'Corrigé';
            case 'evaluation':
                return 'Evaluation';
            case 'guide':
                return 'Guide';
            case 'media':
                return 'Media';
            case 'positionnement':
                return 'Positionnement';
            case 'url':
                return 'URL';
            case 'preparation':
                return 'Préparation';
            case 'ressource':
                return 'Ressource';
            case 'tp':
                return 'TP';
            case 'travail':
                return 'Mise en application';
            case 'url':
                return 'URL';
            case 'presentiel':
                return 'Activité présentielle';
            case 'devoir':
                return 'Devoir';
        }
    }

    openWarning(body: string, title: string) {
        this.emitWarningDialog.next({ title: title, body: body });
    }

    openContentDetailsDialog(contentId: number) {
        this.emitContentDetailsDialog.next(contentId);
    }

    emitRefreshLibrary() {
        this.refreshLibrary.next();
    }

    emitContentDisabled(content: Content) {
        this.contentDisabled.next(content);
    }

    emitContentEnabled(content: Content) {
        this.contentEnabled.next(content);
    }

    emitContentArchived(content: Content) {
        this.contentArchived.next(content);
    }

    emitContentUnarchived(content: Content) {
        this.contentUnarchived.next(content);
    }

    emitContentDeleted(content: Content) {
        this.contentDeleted.next(content);
    }

    emitAddedDraft(content: Content) {
        this.router.navigate(['teacher/creation']);
        this.addedDraft.next(content);
    }

    emitUpdatedDraft(content: Content) {
        this.router.navigate(['teacher/creation']);
        this.updatedDraft.next(content);
    }

    emitDeletedDraft(content: Content) {
        this.router.navigate(['teacher/creation']);
        this.deletedDraft.next(content);
    }

    emitDeletedChild(child: Content, parent: Content) {
        this.deletedChild.next({ child, parent });
    }

    emitViewInLibrary(contentId: number) {
        this.viewInLibrary.next(contentId);
    }

    emitFoldAll() {
        this.foldAll.next();
    }

    emitDetectChanges() {
        this.detectChanges.next();
    }

    emitSetNationalCatalog() {
        this.setNationalCatalog.next();
    }
}
